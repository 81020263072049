/* Project CSS */

.projectTitle {
  margin: 30px 0 12px;
  font-size: 24px;
  color: #0094ff;
  font-weight: 600;
}

.fa-edit {
  /* color:#009efb; */
  font-weight: 900;
}

.fa-trash {
  color: #dc3545;
  font-weight: 900;
}

.fa-tv {
  color: #009efb;
  font-weight: 900;
}

.breadcrumb {
  background-color: #fff;
  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}
.badge {
  font-size: 13px;
  padding-top: 6px;
  vertical-align: super;
}
/* Switch radio button Specific Styles  */

input[type="checkbox"].switch_1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #009efb;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.5em);
}

/* Search box */
.form-control {
  background-color: #fafafa;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #009efb;
}

/* pagination */

.entries {
  display: flex;
}
.form-control.show-entries {
  padding: 0;
  margin: 0 5px;
  min-height: 20px;
}

.pagination > .active > a {
  background-color: #8fcaed;
  border-color: #009efb;
  color: #fff;
}

/* modal */

.modal-backdrop.show {
  opacity: 0.8;
  background: grey;
}

[data-toggle="buttons"] > .btn > input[type="radio"],
[data-toggle="buttons"] > .btn > input[type="checkbox"] {
  display: none;
}

/* Switch button */

label.btn-outline-info {
  color: #b1aaaa;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #009efb;
  border-color: #009efb;
}

/* hr {
  border-top: 2px solid rgb(255 255 255 / 39%);
} */

/* End of Project CSS */
.card-title.h5 {
  font-size: 18px;
  margin-bottom: 5px;
}

.card-title p {
  font-size: 14px;
  color: #4a4b4e;
}

/* Feed */
.feed {
  padding: 0;
}
ul.feed {
  list-style-type: none;
}
.feed-title h3 {
  font-size: 24px;
  font-weight: bold;
  color: #002147;
}
span.feed-name {
  font-size: 13px;
  font-weight: 400;
  color: #060c27;
}
.feed-content {
  margin-left: 45px;
}
