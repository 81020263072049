/* Feed */
.card-title h2 {
  font-size: 18px;
  font-weight: bold;
  color: #0094ff;
}
.feed {
  padding: 0;
}
ul.feed {
  list-style-type: none;
}
.feed-title h3 {
  font-size: 24px;
  font-weight: bold;
  color: #002147;

}
span.feed-name {
  font-size: 13px;
  font-weight: 400;
  color: #060c27;
  margin-left: 20px;

}
.feed-content {
  margin-left: 20px;
}
