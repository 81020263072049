@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

Table > thead > tr > th {
  font-size: 16px;
  font-weight: 600;
  color: #098ddf;
}
.projectTitle {
  font-size: 24px;
  margin: 16px 0;
}

/*introduction css*/
.statDiv {
  background-color: #f7fafc;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-bottom: 24px;
}
.statInside {
  display: flex;
  flex-direction: row;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
}
.statInside div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progressVal {
  color: #098ddf;
  font-weight: 700;
}
.progressBar {
  margin: 8px 0 16px;
}
.barLeft {
  border-left: 2px solid #ddd;
}

.dash-widget {
  background-color: #fff;
  border: 2px solid #f7fafc;
  transition: border 0.5s;
}
.dash-widget:hover {
  background-color: #f7fafc;
  border: 2px solid #098ddf;
  transition: border 0.5s;
  cursor: pointer;
}
.small-text {
  font-size: 11px;
}
.big-text {
  font-size: 24px;
  font-weight: 700;
}

.project-info > li {
  border: 0;
  background-color: #009efb;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  border-radius: 5px;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #009efb;
  border-color: #0d6efd;
}
.makeStyles-paper-2 {
  overflow: scroll;
}
.btn:hover .btn-filter {
  color: #fff;
}

.hsImage {
    margin: 16px auto;
    display: flex;
    height: 62px;
    width: 280px;
    object-fit: contain;
}
.localText {
    color: #0c4aac;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
}
.outerDiv {
    background-color: #f5f5f5;
    border-radius: 32px;
    margin: 24px;
    padding: 10px;
    box-shadow: 1px 1px 1px #cccccc;
    -webkit-box-shadow: 1px 1px 1px #cccccc;
    -moz-box-shadow: 1px 1px 1px #cccccc;
    font-family: "Open Sans", sans-serif;
}
.innerDiv {
    /* background-image: url("1.png"); */
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    background-color: #fff;
    box-shadow: 1px 4px 2px #d2d2d2;
    font-family: "Open Sans", sans-serif;
    padding: 24px 0;
}
.nameDiv {
    flex: 1 1;
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 16px;
}
.bigText {
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #1c1c1c;
}
.smallBoldText {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #1c1c1c;
}
.smallText {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #1c1c1c;
}
.itemGradient {
    background-image: linear-gradient(to right, #c94b4b, #4b134f);
    padding: 16px 0;
    margin-bottom: 16px;
}
.itemGradientInner {
    padding: 0pc 8px;
    display: flex;
    flex-direction: row;
}
.prices {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 8px;
}
.bigTextCenter {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #fff;
    text-align: center;
    flex: 1 1;
}
.itemText {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    padding: 4px 0;
    text-align: center;
    flex: 1 1;
}
.outerText {
    font-size: 20px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    padding: 16px 0;
}
.invoiceBg {
    /*background: url(../assets/imageIcon/gradient.png) bottom no-repeat;*/
    background-size: contain;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    padding-bottom: 64px;
    /*height: 100vh;*/
}
.invoiceBtn {
    background-image: linear-gradient(to right, #c94b4b, #4b134f);
    width: 93%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-top: 16px;
    box-shadow: 1px 1px 2px #333;
    -webkit-box-shadow: 1px 1px 2px #333;
    -moz-box-shadow: 1px 1px 2px #333;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
    padding: 16px;
}
.getBtnText {
    font-family: "Open Sans";
    font-size: 22px;
    font-weight: 700;
    height: 50px;
    /*margin-right: 16px;*/
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
}
.totalDiv {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #707070;
    margin-top: 16px;
    width: 100%;
    justify-content: flex-end;
    margin-left: 16px;
}
.imageClass {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    background-color: #aaa;
    margin: 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imageBigText {
    font-size: 50px;
    color: #fff;
    font-weight: 700;
}
.payText {
    flex-direction: row;
    font-size: 12px;
    color: #707070;
    text-align: center;
    padding: 16px 0;
}
.accountText {
    font-size: 14px;
    color: #707070;
    margin: 4px 16px;
    font-weight: 700;
}
.valueText {
    font-size: 18px;
    color: #000;
    margin: 4px 16px;
}
.cardWrapper {
    padding: 8px 25px;
    margin: 20px 0;
    border-radius: 4px;
    background-color: #f5f5f5;
    position: relative;
}
.payButton {
    display: block;
    height: 50px;
    width: 100%;
    font-size: inherit;
    background-color: #0c4aac;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    box-shadow: black;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.greenButton {
    padding: 16px;
    width: 92%;
    height: 50px;
    background-color: #57b182;
    margin: 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 4px;
    color: #ffffff;
    border-radius: 4px;
}
.redButton {
    padding: 16px;
    width: 92%;
    height: 50px;
    background-color: red;
    margin: 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 4px;
    color: #ffffff;
    border-radius: 4px;
}
.payDone {
    position: fixed;
    /*display: none;*/
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    cursor: pointer;
}
.payDoneDiv {
    position: absolute;
    /* text-align: center; */
    height: 230px;
    width: 300px;
    top: 50%;
    left: 50%;
    background-color: #f2f2f2;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    border-radius: 21px;
}
.payDivText {
    font-size: 26px;
    font-weight: 600;
    color: #000;
}
@media only screen and (min-width: 768px) {
    .outerDiv {
        width: 600px;
        margin: 24px auto;
    }
    .greenButton,
    .payButton {
        width: 600px;
        margin: 10px auto;
    }
}

/* Project CSS */

.projectTitle {
  margin: 30px 0 12px;
  font-size: 24px;
  color: #0094ff;
  font-weight: 600;
}

.fa-edit {
  /* color:#009efb; */
  font-weight: 900;
}

.fa-trash {
  color: #dc3545;
  font-weight: 900;
}

.fa-tv {
  color: #009efb;
  font-weight: 900;
}

.breadcrumb {
  background-color: #fff;
  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}
.badge {
  font-size: 13px;
  padding-top: 6px;
  vertical-align: super;
}
/* Switch radio button Specific Styles  */

input[type="checkbox"].switch_1 {
  -webkit-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #009efb;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.5em);
}

/* Search box */
.form-control {
  background-color: #fafafa;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #009efb;
}

/* pagination */

.entries {
  display: flex;
}
.form-control.show-entries {
  padding: 0;
  margin: 0 5px;
  min-height: 20px;
}

.pagination > .active > a {
  background-color: #8fcaed;
  border-color: #009efb;
  color: #fff;
}

/* modal */

.modal-backdrop.show {
  opacity: 0.8;
  background: grey;
}

[data-toggle="buttons"] > .btn > input[type="radio"],
[data-toggle="buttons"] > .btn > input[type="checkbox"] {
  display: none;
}

/* Switch button */

label.btn-outline-info {
  color: #b1aaaa;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #009efb;
  border-color: #009efb;
}

/* hr {
  border-top: 2px solid rgb(255 255 255 / 39%);
} */

/* End of Project CSS */
.card-title.h5 {
  font-size: 18px;
  margin-bottom: 5px;
}

.card-title p {
  font-size: 14px;
  color: #4a4b4e;
}

/* Feed */
.feed {
  padding: 0;
}
ul.feed {
  list-style-type: none;
}
.feed-title h3 {
  font-size: 24px;
  font-weight: bold;
  color: #002147;
}
span.feed-name {
  font-size: 13px;
  font-weight: 400;
  color: #060c27;
}
.feed-content {
  margin-left: 45px;
}

/* Feed */
.card-title h2 {
  font-size: 18px;
  font-weight: bold;
  color: #0094ff;
}
.feed {
  padding: 0;
}
ul.feed {
  list-style-type: none;
}
.feed-title h3 {
  font-size: 24px;
  font-weight: bold;
  color: #002147;

}
span.feed-name {
  font-size: 13px;
  font-weight: 400;
  color: #060c27;
  margin-left: 20px;

}
.feed-content {
  margin-left: 20px;
}



