.project-info > li {
  border: 0;
  background-color: #009efb;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  border-radius: 5px;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #009efb;
  border-color: #0d6efd;
}
.makeStyles-paper-2 {
  overflow: scroll;
}
.btn:hover .btn-filter {
  color: #fff;
}
