Table > thead > tr > th {
  font-size: 16px;
  font-weight: 600;
  color: #098ddf;
}
.projectTitle {
  font-size: 24px;
  margin: 16px 0;
}

/*introduction css*/
.statDiv {
  background-color: #f7fafc;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-bottom: 24px;
}
.statInside {
  display: flex;
  flex-direction: row;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
}
.statInside div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progressVal {
  color: #098ddf;
  font-weight: 700;
}
.progressBar {
  margin: 8px 0 16px;
}
.barLeft {
  border-left: 2px solid #ddd;
}

.dash-widget {
  background-color: #fff;
  border: 2px solid #f7fafc;
  transition: border 0.5s;
}
.dash-widget:hover {
  background-color: #f7fafc;
  border: 2px solid #098ddf;
  transition: border 0.5s;
  cursor: pointer;
}
.small-text {
  font-size: 11px;
}
.big-text {
  font-size: 24px;
  font-weight: 700;
}
